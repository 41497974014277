import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/Layout'
import Presentations from '../components/Presentations'

class Speaking extends React.Component {
  render() {
    const presentations = get(this, 'props.data.presentations.edges')
    return (
      <Layout>
        <Presentations list={presentations} />
      </Layout>
    )
  }
}

export default Speaking

{
  /* Currently using aliases to query specific folders in our data */
}
{
  /* Specific sections of data can be accessed via props.data.__category__.fields */
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    presentations : allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {fileAbsolutePath: {regex: "/presentations/.*\\.md$/"}}
    ) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            location
            source
            eventLink
            youtubeLink
          }
        }
      }
    }
  }
`
