import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import YouTubeIcon from '../assets/social/Youtube-black.png'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import SectionTitle from '../components/SectionTitle'

const PresentationsContainer = styled.div`
  margin: 0 auto 2% 0;
`

const Section = styled.div`
  justify-content: end;
  display: flex;
  padding: 1.4rem;
  width: 100vw;
  max-width: 100%;

  @media screen and (max-width: 1100px) {
    overflow-x: auto;
    padding: 0;
  }
`

const Table = styled.table`
  margin: 4vh auto 4vh auto;
  color: #313639;
  max-width: 64rem;

  @media screen and (max-width: 900px) {
    max-width: 98vw;
  }
`

const Row = styled.tr`
  :last-child {
    > td {
      border-bottom: 0;
    }
  }
`

const Header = styled.th`
  border: 1px solid rgba(197, 198, 199, 0.3);
  border-top: none;
  padding-left: 12px !important;

  :first-child {
    border-left: none;
  }

  :last-child {
    border-right: none;
  }
`

const Item = styled.td`
  border: 0.1px solid rgba(197, 198, 199, 0.3);
  padding-left: 12px !important;
  font-size: 14px;

  :first-child {
    border-left: none;
  }

  :last-child {
    border-right: none;
  }

  a {
    text-decoration: none;
    box-shadow: none;

    :hover {
      opacity: 1.4;
    }

    img {
      opacity: 0.6;
      height: 24px;
      margin: 0;
      margin-left: 8px;
      transform: translateY(6px);

      :hover {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 900px) {
    font-size: 12px;
    line-height: 16px;
    padding: 0.8rem;

    a {
      img { 
        margin: 0;
      }
    }
  }
`

const MobileBreak = styled.br`
  display: none;

  @media screen and (max-width: 900px) {
    display: inline;
  }
`

class Presentations extends React.Component {
  render() {
    return (
      <PresentationsContainer id="presentations">
        <SectionTitle text="Speaking engagements" />
        <Section>
          <Table style={{ marginTop: '4vh', color: '#313639' }}>
            {this.props.list.map(({ node }) => {
              const title = get(node, 'frontmatter.title') || node.fields.slug
              const location = get(node, 'frontmatter.location' || '')
              const date = get(node, 'frontmatter.date' || location)
              const youtubeLink = get(node, 'frontmatter.youtubeLink')
              if (youtubeLink !== '') {
                console.log(youtubeLink)
                return (
                  <Row key={node.fields.slug}>
                    <Item style={{ display: 'flex' }}>
                      <a href={youtubeLink} target="_blank">
                        {title}
                        <MobileBreak />
                        <img src={YouTubeIcon} />
                      </a>
                    </Item>
                    <Item>{location}</Item>
                    <Item>{date}</Item>
                  </Row>
                )
              } else {
                return (
                  <Row key={node.fields.slug}>
                    <Item>
                      {title} <br />
                    </Item>
                    <Item>{location}</Item>
                    <Item>{date}</Item>
                  </Row>
                )
              }
            })}
          </Table>
        </Section>
      </PresentationsContainer>
    )
  }
}

export default Presentations
